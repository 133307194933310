<template>
	<div ref="exper" class="exper">
		<van-nav-bar
			title="評估結果"
			left-arrow
			fixed
			:z-index="1000"
			class="qjc-nav-bar"
			@click-left="$router.back()"
		>
			<van-image 
				:src="require('@/assets/images/home.png')" 
				slot="right"
				width="0.32rem"
				height="0.32rem"
				style="vertical-align: middle;"
				@click="$router.push('/aizh')"
			/>
		</van-nav-bar>
		<div class="banner">
			
		</div>
		
		<div class="result">
			<div class="branch_wrap">
				<div class="branch_score">
					<div class="branch_content">
						<div class="branch_item_title">
							健康評分
							<i @click="scoreTip1 = !scoreTip1" class="iconfont iconyiwen1 z_index9 qjc-relative">
								<div v-if="scoreTip1" class="score_tip_box qjc-bg-fff qjc-absolute">
									<span class="score_tip_text">米因以大數據為基礎，綜合個人的當前健康狀況、潜在疾病風險和未來健康風險進行整體評分，給出百分制的「健康分」，以求盡可能對整體健康進行量化評估，既可用於表示個人健康狀況，也可作為個人健康管理的目標和評估標準。
									<br><span class="score_tip_span">对于个人而言，分數越高則相對越健康，健康分不適用於85歲以上的長者。</span></span>
									<div class="arrow"></div>
								</div>
							</i>
						</div>
						<div class="score_info_top" :style="{'--infoLeft': scoreVal > 3 ? scoreVal - 3 + '%' : scoreVal + 2 + '%' }">
							<div class="score_wrap">
								<div class=""> {{res.base.age > 85 ? '健康分不適用於85歲以上的長者' : '您的健康評分'}}</div>
								<div class="score_info_top_info" v-if="res.base.age <= 85">
									<span :class="scoreVal <= 59 ? 'score_color3 score_val' : (scoreVal > 59 && scoreVal <= 89) ? 'score_color2 score_val' : 'score_color1 score_val' ">{{scoreVal}}</span><span :class="scoreVal <= 59 ? 'score_color3 score_unit' : (scoreVal > 59 && scoreVal <= 89) ? 'score_color2 score_unit' : 'score_color1 score_unit' ">
									分({{scoreVal <= 59 ? '欠佳' : (scoreVal > 59 && scoreVal <= 89) ? '良好' : scoreVal > 89 ? '優等' : ''}})</span>
									<img v-if="scoreVal <= 59" class="xiao_icon" src="@/assets/images/health_3.png" />
									<img v-if="scoreVal > 59 && scoreVal <= 89" class="xiao_icon" src="@/assets/images/health_2.png" />
									<img v-if="scoreVal > 89" class="xiao_icon" src="@/assets/images/health_1.png" />
								</div>
							</div>
						</div>
						<div class="score_line">
							<div class="score_one_bg"></div>
							<div class="score_d1"></div>
							<div class="score_two_bg"></div>
							<div class="score_d2"></div>
							<div class="score_three_bg"></div>
							<div class="score_line_top">
								<div class="score_one" :style="{width: (scoreVal <=59 ? scoreVal + '%' : '59%')}"></div>
								<div v-if="scoreVal > 59" class="score_two" :style="{width: ((scoreVal > 59 && scoreVal <= 89) ? scoreVal - 59 + '%' : '30%')}"></div>
								<div v-if="scoreVal > 89"  class="score_three" :style="{width: (scoreVal > 89 ? scoreVal - 89 + '%' : '11%')}"></div>
								<div :class="scoreVal <=59 ? 'score_circle3' : (scoreVal > 59 && scoreVal <= 89) ? 'score_circle2' : scoreVal > 89 ? 'score_circle1' : '' " :style="{left: scoreVal - 2 + '%'}"></div>
							</div>
						</div>
						<div class="score_text">健康分等級：100-90分以上視為「優等」； 89-60分視作「良好」； 59-1分視為「欠佳」。</div>
					</div>
				</div>
			</div>
			<div class="branch_wrap">
				<div class="branch_health branch_score">
					<div class="branch_content">
						<div class="branch_item_title">
							人群分類
						</div>
						<div class="branch_health_list">
							<div :class="res.base.categ == 'HEA1' ? 'branch_health_item branch_health_1 branch_health_scale' :  'branch_health_item'">健康<span :class="res.base.categ == 'HEA1' ? 'branch_health_item_ca' : ''">人群</span></div>
							<div :class="res.base.categ == 'HEA2' ? 'branch_health_item branch_health_2 branch_health_scale' :  'branch_health_item'">亞健康<span :class="res.base.categ == 'HEA2' ? 'branch_health_item_ca' : ''">人群</span></div>
							<div :class="res.base.categ == 'HEA3' ? 'branch_health_item branch_health_3 branch_health_scale' :  'branch_health_item'">醫療需求<span :class="res.base.categ == 'HEA3' ? 'branch_health_item_ca' : ''">人群</span></div>
							 <!-- branch_health_scale -->
						</div>
						<div class="branch_health_list">
							<div class="branch_health_item_text score_color1">{{res.base.categ == 'HEA1' ? '您屬於' : ''}}</div>
							<div class="branch_health_item_text score_color2">{{res.base.categ == 'HEA2' ? '您屬於' : ''}}</div>
							<div class="branch_health_item_text score_color3">{{res.base.categ == 'HEA3' ? '您屬於' : ''}}</div>
						</div>
						
						<van-grid :column-num="3" :border="false">
							<van-grid-item class="qjc-nowrap">
								<span>身高：<b class="qjc-c-dark">{{ res.base.height }}cm</b></span>
							</van-grid-item>
							<van-grid-item class="qjc-nowrap">
								<span>體重：<b class="qjc-c-dark">{{ res.base.weight }}kg</b></span>
							</van-grid-item>
							<van-grid-item class="qjc-nowrap">
								<span>體脂率：<b class="qjc-c-dark">{{ res.base.fat ? res.base.fat : '--' }}%</b></span>
							</van-grid-item>
							<van-grid-item class="qjc-nowrap">
								<span>BMI：<b class="qjc-c-dark">{{ res.base.bmi ? res.base.bmi : '--' }}</b></span>
							</van-grid-item>
							<van-grid-item class="qjc-nowrap">
								<span>腰圍：<b class="qjc-c-dark">{{ res.base.waist ? res.base.waist : '--'  }}cm</b></span>
							</van-grid-item>
							<van-grid-item class="qjc-nowrap">
								<span>臀圍：<b class="qjc-c-dark">{{ res.base.hip ? res.base.hip : '--'  }}cm</b></span>
							</van-grid-item>
						</van-grid>
					</div>
				</div>
			</div>
			<van-grid class="person qjc-bg-fff" :column-num="3" :border="false">
				<van-grid-item>
					<dl>
						<dt>體型評估</dt>
						<dd class="qjc-c-primary"><b>{{ res.m05.body_g[1] }}</b></dd>
					</dl>
					<dl>
						<dt>
							未來一年的醫療費用
						</dt>
						<dd class="qjc-c-primary">HK$<b>{{ res.m10.person_yearsfee ? res.m10.person_yearsfee[res.m10.person_yearsfee.length -1] : '' }}</b>港元</dd>
					</dl>
				</van-grid-item>
				<van-grid-item>
					<van-image
						width="1.84rem"
						height="5.5rem"
						:src="this.res.base.sex == 1?require('@/assets/images/man.png'):(this.res.base.sex == 2?require('@/assets/images/wuman.png'):null)"
					/>
				</van-grid-item>
				<van-grid-item>
					<dl>
						<dt>百歲概率</dt>
						<dd class="qjc-c-primary"><b>{{ res.m01.lift_rates_100 }}</b>%</dd>
					</dl>
					<dl  v-if="res.m01.lift_rates_90">
						<dt>九十歲概率</dt>
						<dd class="qjc-c-primary"><b>{{ res.m01.lift_rates_90 ? res.m01.lift_rates_90 + '%' : '--' }}</b></dd>
					</dl>
					
				</van-grid-item>
			</van-grid>
			
			<!-- 体型评估 -->
			<div class="bodily">
				<h3 class="module-title">體型評估</h3>
				<p class="qjc-fts-28 qjc-c-dark qjc-texta-l">體型是影響健康的重要係數之一。 不健康的生活方式會直接影響體型，同時帶來健康風險。</p>
				<div class="qjc-clearfix">
					<div class="qjc-fl">
						<van-image
							width="1.12rem"
							height="1.12rem"
							:src="require('@/assets/images/experience_BFR.png')"
						/>
						<p class="qjc-fts-28 qjc-c-dark">體脂率<span class="qjc-c-primary qjc-ftw-b">{{ ['1','4','7'].indexOf(res.m05.body_g[0])!=-1?'偏低':(['2','5','8'].indexOf(res.m05.body_g[0])!=-1?'適中':(['3','6','9'].indexOf(res.m05.body_g[0])!=-1?'偏高':'未知')) }}</span></p>
					</div>
					<div class="qjc-fr">
						<van-image
							width="1.12rem"
							height="1.12rem"
							:src="require('@/assets/images/experience_BMI.png')"
						/>
						<p class="qjc-fts-28 qjc-c-dark">BMI<span class="qjc-c-primary qjc-ftw-b">{{ res.m05.bmi_g[1] }}</span></p>
					</div>
				</div>
				<dl class="group">
					<dt class="qjc-fts-24">
						在同類族群中屬於
						<i @click="describe" class="group_icon iconfont iconyiwen1 qjc-relative">
							<div class="center qjc-absolute qjc-c-fff qjc-fts-24">
								同族群（PLM，People Like Me）:與您相似的人群--與您同性別同年齡段的人群
							</div>
						</i>
					</dt>
					<dd class="qjc-fts-36 qjc-ftw-b qjc-c-primary">{{ res.m05.body_g[1] }}</dd>
				</dl>
				<div class="bmi_tips">{{res.m05.body_g[2]}}</div>
			</div>
			
			<!-- 体型改善 -->
			<div class="bodily-improve">
				<h3 class="module-title">體重及體型改善方案（12週）</h3>
				<p class="qjc-fts-28 qjc-c-dark qjc-texta-l">每個人都有適合自己的最佳體型，過胖或過瘦都不好，請注意體型的改善和維持。</p>
				<div>
					<van-image
						width="1.21rem"
						height="1.21rem"
						:src="require('@/assets/images/experience_weight.png')"
					/>
					<div class="qjc-clearfix">
						<dl class="qjc-fl qjc-fts-24">
							<dt>當前體重</dt>
							<dd class="c-red qjc-ftw-b">
								<b class="fonts-40">{{ res.m12.sugweight[0] }}</b>kg
							</dd>
						</dl>
						<dl class="qjc-fr qjc-fts-24">
							<dt>建議體重</dt>
							<dd v-if="res.m12.sugweight.length > 1" class="qjc-c-primary qjc-ftw-b"><b class="fonts-40">{{ res.m12.sugweight.slice(-1)[0] }}</b>kg</dd>
							<dd v-else>
								<van-image
									width="0.3rem"
									height="0.34rem"
									@click="more = true"
									:src="require('@/assets/images/lock_l.png')"
								></van-image>
							</dd>
						</dl>
					</div>
				</div>
				<div>
					<van-image
						width="1.21rem"
						height="1.21rem"
						:src="require('@/assets/images/experience_WHR.png')"
					/>
					<div class="qjc-clearfix">
						<dl class="qjc-fl qjc-fts-24">
							<dt>當前腰臀比</dt>
							<dd class="c-red qjc-ftw-b"><b class="fonts-40">{{ res.m12.sugwhr[0] }}</b></dd>
						</dl>
						<dl class="qjc-fr qjc-fts-24">
							<dt>建議腰臀比</dt>
							<dd v-if="res.m12.sugwhr.length > 1" class="qjc-c-primary qjc-ftw-b"><b class="fonts-40">{{ res.m12.sugwhr.slice(-1)[0] }}</b></dd>
							<dd v-else>
								<van-image
									width="0.3rem"
									height="0.34rem"
									@click="more = true"
									:src="require('@/assets/images/lock_l.png')"
								></van-image>
							</dd>
						</dl>
					</div>
				</div>
			</div>
			
			<!-- 重症预警(医疗需求者) 慢病风险(亚健康) 系统风险(健康) -->
			<div class="illness">
				<h3 class="module-title">{{ res.base.categ == 'HEA3'?'重症預警':(res.base.categ == 'HEA2'?'慢病風險':(res.base.categ == 'HEA1'?'系統風險':'----')) }}</h3>
				<p class="qjc-fts-28 qjc-c-dark qjc-texta-l">
					<span v-if="res.base.categ == 'HEA3'">長期患病往往會導致各種危重症急性發作，伴隨健康狀況的迅速惡化，甚至危及生命。 急重症會帶來數十萬的醫療花費，並對身體造成巨大的、不可逆的傷害。 這是您患病風險概率最高的一種急危重症。</span>
					<span v-if="res.base.categ == 'HEA2'">亞健康狀態作為人體的第三狀態，可以向健康和疾病兩個方向轉化。 若人體長期處於亞健康狀態，沒有及時進行健康管理和疾病預防，將極有可能誘發一些慢性疾病。 這是您患病風險概率最高的二種慢性疾病。</span>
					<span v-if="res.base.categ == 'HEA1'">系統患病風險概率包含了從微小疾病到重大疾病的所有風險概率。 人體共有九大生理系統，這是您患病風險概率最高的五個系統。</span>
				</p>
				<div v-for="(item,index) in dis_risk" :key="index">
					<dl>
						<dt class="devg qjc-relative qjc-fts-32 qjc-c-dark qjc-ftw-b">
							<!-- 风险排序 -->
							<div class="sort qjc-absolute qjc-fts-24">
								<span>風險排序:</span>
								<van-image
									height="0.15rem"
									:src="require('@/assets/images/illness_no' + (index+1) +'.png')"
								></van-image>
							</div>
							
							<span v-if="item.devg" :class="{ 'text-c': true }">{{ item.devg }}</span>
							<van-image
								v-else
								width="0.3rem"
								height="0.34rem"
								@click="more = true"
								:src="require('@/assets/images/lock_l.png')"
							></van-image>
						</dt>
					</dl>
					<div class="chart" :class="'risk'+index" :key="item.devg">
						
					</div>
					<p class="symptom qjc-texta-l qjc-fts-28 qjc-c-dark">
						主要症狀：<span v-if="item.desc">{{ item.desc }}</span><span v-else>---</span>
					</p>
					<div class="ai_jy" v-if="res.base.categ != 'HEA1'">
						<div class="ai_jy_close" v-if="openList.indexOf(item.no) == -1 && no != item.no">
							<div class="ai_jy_touxiang">
								<van-image class="close_avatar" :src="require('@/assets/images/touxiang.png')"></van-image>
							</div>
							<div class="ai_jy_query">
								<div class="ai_jy_query_p">
									您好，我是您的AI健康小助手<br>
									這裡有一份關於預防<span>{{item.devg}}</span>的檢查建議哦~
								</div>
								<div class="ai_jy_btn_cell">
									<van-button class="ai_jy_btn" @click="handleOpen(item.no)">
										<span>點擊查看</span>
										<van-image class="ai_jy_btn_icon" :src="require('@/assets/images/send_icon.png')"></van-image>
									</van-button>
								</div>
							</div>
						</div>
						<div class="ai_jy_load" v-if="no == item.no && isLoading">
							<div class="ai_jy_touxiang">
								<van-image class="close_avatar" :src="require('@/assets/images/touxiang.png')"></van-image>
							</div>
							<div class="ai_jy_load_cell">
								<div class="chat_text">稍等片刻，正在為您解答 <span class="tips1" v-if="isTip1"></span><span class="tips2" v-if="isTip2"></span><span class="tips3" v-if="isTip3"></span> </div>
							</div>
						</div>
						<div class="ai_jy_open" v-if="openList.indexOf(item.no) != -1">
							<div class="ai_jy_q">
								<div class="ai_jy_touxiang">
									<van-image class="close_avatar" :src="res.base.sex == 1 ? require('@/assets/images/nan-avatar.png') : require('@/assets/images/nv-avatar.png')"></van-image>
								</div>
								<div class="ai_jy_q_cell">
									为了预防{{item.devg}}，我应该做哪些检查?
								</div>
							</div>
							<div class="ai_jy_content">
								<div class="ai_jy_touxiang">
									<van-image class="close_avatar" :src="require('@/assets/images/touxiang.png')"></van-image>
								</div>
								<div class="ai_jy_html" v-html="item.supp"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 未来医疗费用预测 -->
			<div class="cost">
				<h3 class="module-title">未來醫療費用預測</h3>
				<p class="qjc-fts-28 qjc-c-dark qjc-texta-l">
					基於您的總體健康狀況和疾病風險，預測您的未來一年醫療費用。
				</p>
				<div class="costs-chart" key="costChart">
					
				</div>
			</div>
			
			<p class="footer">最終解釋權歸上海米因大數據科技有限公司所有</p>
		
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Grid, GridItem, Image, Toast, Popup, Button } from 'vant'
	Vue.use(NavBar)
	   .use(Grid)
	   .use(GridItem)
	   .use(Image)
	   .use(Toast)
	   .use(Button)
	   .use(Popup);
	
	import { toggleClass, accMul } from '@/js/utils.js'
	
	// 获取结果数据
	import { resultCommit } from '@/js/axios.js'
	
	// html2canvas生成长图
	import html2canvas from 'html2canvas'
	var echarts = require('echarts');
	
	export default{
		name: 'resultExperience',
		data (){
			return {
				isLoading: false,//正在加载
				progress: 0,//进度值 0-100
				nowProgress: 50,//当前进度
				
				timer: '',//长按保存定时器
				saveImgSrc: '',//页面存为长图的src
				
				id: this.$route.params.id,// 当前结果id
				scoreVal: '',
				scoreTip: false, //健康分 tip
				scoreTip1: false,
				res: {
					amount: '--',//价格
					name: '--',//名称
					base: {
						health_type: '',//所属人群
						height: '',//身高
						weight: '',//体重
						fat: '',//体脂率
						bust: '',//胸围
						waist: '',//腰围
						hip: '',//臀围
						sex: '',//男女 1男 2女
					},
					m01: {
						pre_lift: '',//预期寿命
						lift_rates: '',//百岁概率
					},
					m02: {
						realage: ''
					},
					m05: {
						body_g: [],
						bmi_g: []
					},
					m07: {
						dis_risk: []
					},
					m10: {
						person_yearsfee: ''
					},
					m12: {
						sugweight: [],
						sugwhr: []
					},
					m14: {
						bmr: '',
						c0: '',
						bmr_df: '',
						c21: '',
						c12: '',
						c31: '',
						c41: '',
						c51: ''
					},
					m15: ''//只有四高 概率最大的一个
				},
				dis_risk: [],
				openList: [],
				more: false, //了解更多
				isTip1: false,
				isTip2: false,
				isTip3: false,
				no: ''
			}
		},
		watch: {
			res() {//如果接口已获取数据，进度100%
				if(this.progress < 50){
					this.nowProgress = 100;
				}else{
					this.nowProgress = 100;
					this.progressBar();
				}
			},
			progress(val) {//进度条100结束过渡
				if(val == 100){
					this.isLoading = false;
				}
			},
			isLoading(newVal, oldVal) {
				if(newVal) {
					this.isTip1 = true
					setTimeout(()=> {
						this.isTip2 = true
					}, 500);
					setTimeout(()=> {
						this.isTip3 = true
					}, 1000);
				}
			}
		},
		mounted (){
			this.$nextTick(() => {
				//进度条
				this.progressBar();
				
				// 获取结果数据
				this.getResult(this.$route.params.id);
			})
			
		},
		methods: {
			//进度条结束生成全内容长图
			creatImg() {
				this.$nextTick(() => {
					html2canvas(this.$refs.exper,{
						useCORS: true,
						logging: false,
						ignoreElements: (element) => {
							if(element.parentNode.className.match(/iconfont iconyiwen1/) || element.className == 'suspend'){
								return true;
							}
						}
					}).then(canvas => {
						this.saveImgSrc = canvas.toDataURL();
					});
				})
			},
			
			//进度条 参数：当前进度
			progressBar() {
				var speed = this.progress>40 && this.progress<49 ?(10-(50-this.progress))*50:25;//速率(减速再加速)
				
				if(this.progress<this.nowProgress){
					++this.progress;
					setTimeout(() => {
						this.progressBar();
					},speed);
				}
			},
			handleOpen(no) {
				this.no = no
				this.isLoading = true
				setTimeout(() => {
					this.openList.push(no)
					this.isLoading = false
					this.isTip1 = false
					this.isTip2 = false
					this.isTip3 = false
				}, 900)
			},
			
			getResult (id){
				resultCommit(id).then(data => {
					if(data.code == 200){
						let resule = JSON.parse(window.atob(data.data)); // 解码
						console.log(resule)
						if(resule.base.categ == "HEA1") {
							resule.base.health_type = 1
						} else if(resule.base.categ == "HEA2") {
							resule.base.health_type = 2
						} else if(resule.base.categ == "HEA3") {
							resule.base.health_type = 3
						}
						this.res = resule;
						
						this.scoreVal = resule.base.mlev ? resule.base.mlev : 0;
						
						let dis_risk = resule.m07.dis_risk;
						this.dis_risk = dis_risk
						setTimeout(() => {
							this.dis_risk.map((item, index) => {
								if(this.res.base.categ != "HEA1") {
									item.supp = item.supp.replaceAll('\r\n\r\n', '<br>')
									item.supp = item.supp.replaceAll('\n', '<br>')
								}
								this.illnessChart(`.risk${index}`, item.person_rates, item.compare_rates? item.compare_rates : [])
							})
							
							this.costsChart(resule.m10.person_yearsfee,(resule.m10.compare_yearsfee && resule.base.health_type != 1) ? resule.m10.compare_yearsfee : []);
						}, 500)
					}else{
						Toast.fail('未查找到报告');
						// this.$router.replace('/aizh/user');
					}
					
				})
			},
			
			describe (e){//只作用于疑问icon图标
				var target = e.target;
				if(target.className.indexOf('iconyiwen1') != -1){
					toggleClass(target.children[0], 'show');
				}
			},
			
			arrMax (arr){
				var max = arr[0];//默认取第一个
				for(var i=1;i<arr.length;i++){//从第二个开始筛选
					arr[i]>max?max=arr[i]:'';
				}
				return max;
			},//数组中最大值
			illnessChart (el, person, compare){
				var option = {
						tooltip: {
							trigger: 'axis',
							formatter: '{b}'
						},
						legend: {
							data: ['個人患病概率','同族群患病概率'],
							icon: 'roundRect',
							textStyle: {
								color: '#777F8F',
								fontSize: 12
							},
							itemWidth: 8,
							itemHeight: 4,
							itemGap: 20
						},
						xAxis: {
							boundaryGap: false,
							axisLabel: {
								color: '#777F8F',
								interval: 0
							},
							axisLine: {
								show: false
							},
							axisTick: {
								show: false
							},
							splitLine: {
								show: 'true',
								lineStyle: {
									color: '#f5f5f5'
								}
							},
							data: (function(){
								var arr = [];
								for(var i=0;i<person.length;i++){
									arr.push('第'+(i+1)+'年');
								}
								return arr;
							})()
						},
						yAxis: {
							name: '%',
							type: 'value',
							axisLabel: {
								color: '#777F8F'
							},
							axisLine: {
								show: false
							},
							axisTick: {
								show: false
							},
							splitLine: {
								lineStyle: {
									color: '#f5f5f5'
								}
							}
						},
						series: [
							{
								name: '個人患病概率',
								type: 'line',
								data: person,
								label: {
								  show: true,
								  color: '#6883FB',
								  fontSize: 13,
								  fontWeight: '500',
								  formatter: function(params) {
								    return params.value + '%'
								  },
								},
							},
							{
								name: '同族群患病概率',
								type: 'line',
								data: compare,
								label: {
								  show: true,
								  color: '#65D448',
								  fontSize: 13,
								  fontWeight: '500',
								  formatter: function(params) {
								    return params.value + '%'
								  },
								},
							}
						],
						color: ['#6883FB','#65D448']
					};
				option.tooltip.formatter += '<br>個人患病概率:{c}%';
				if(compare && compare.length > 0){
					option.tooltip.formatter += '<br>同族群患病概率:{c1}%';
					option.series[1].data = compare;
				}else{
					option.legend.data.pop();
					option.series.pop();
				}
				
				this.drawChat(el, option);
			},//未来疾病风险预测 参数：1：疾病列表下标(用了区分图标class) 2:疾病名称 3：疾病列表数据 4：同族群列表数据
			
			costsChart (tenYears, tenYearsGroup){
				var option = {
					title: {
						text: '未來'+ tenYears.length +'年主要醫療費用演化趨勢',
						textStyle: {
							color: '#40444D',
							fontSize: 14
						},
						top: 15,
						left: 'center'
					},
					legend: {
						orient: 'horizontal',
						data: ['您的醫療費用預測','同族群的醫療費用預測'],
						icon: 'roundRect',
						textStyle: {
							color: '#777F8F',
							fontSize: 12
						},
						// // top: 10,
						bottom: 10,
						// left: '40%',
						itemWidth: 8,
						itemHeight: 8,
						itemGap: 10
					},
					backgroundColor: '#fff',
					grid: {
						left: '14%',
						right: '2%'
					},
					tooltip: {
						trigger: 'axis',
						formatter: tenYearsGroup.length > 0 ? function(params) {
							if(params.length == 1) {
								return `${params[0].axisValue}<br>${params[0].seriesName}: ${params[0].value}港元`
							} else {
								return `${params[0].axisValue}<br>${params[0].seriesName}: ${params[0].value}港元<br>${params[1].seriesName}: ${params[1].value}港元`
							}
						} : '<br>{b0}',
						backgroundColor: '#FCFDFF',
						confine: true,
						textStyle: {
							color: '#666',
							fontSize: 12,
							fontWeight: 300
						},
						extraCssText: 'box-shadow: 0px 10px 20px 0px rgba(136,144,152,0.14);text-align: left;'
					},
					xAxis: {
						type: 'category',
						nameTextStyle: {
							color: '#777F8F'
						},
						axisLabel: {
							color: '#777F8F',
							interval: 0
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						data: (() => {
							var x = [];
							for(var i=0;i<tenYears.length;i++){
								x.push('第'+ (i+1) + '年');
							}
							return x;
						})()
					},
					yAxis: {
						name: 'HK $      ',
						nameTextStyle: {
							color: '#777F8F'
						},
						axisLabel: {
							color: '#777F8F'
						},
						axisLine: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: '#f5f5f5'
							}
						},
						axisTick: {
							show: false
						},
					},
					series: [
						{
							type: 'bar',
							name: '您的醫療費用預測',
							itemStyle: {
								color: new echarts.graphic.LinearGradient(
								  0, 0, 0, 1,//渐变色在下面修改，这里是透明度
								  [{
									  offset: 0,
									  color: 'rgba(83, 131, 255, 1)',
									  borderRadius: 8
									},
									{
									  offset: 1,
									  color: 'rgba(161, 188, 255, 1)',
									  
									}
								  ]
								),
								barBorderRadius: 8
							},
							barWidth: 8,
							data: [],
							label: {
							  show: true,
							  color: '#7A8599',
							  position: 'top',
							  fontSize: 13,
							  fontWeight: '500',
							  formatter: function(params) {
								return params.value + '港元'
							  }
							}
						},
						{
							type: 'bar',
							name: '同族群的醫療費用預測',
							itemStyle: {
								normal: {
									color: '#E6E8EB',
									barBorderRadius: 8
								},
								emphasis: {
									color: '#E6E8EB',
									opacity: '0.8',
									barBorderRadius: 8
								},
							},
							barWidth: 8,
							data: [],
							label: {
							  show: true,
							  color: '#7A8599',
							  position: 'top',
							  fontSize: 13,
							  fontWeight: '500',
							  formatter: function(params) {
								return params.value + '港元'
							  }
							}
						}
					]
				}
				// 是否有同族群展示
				if(tenYearsGroup){
			
				}
				if(tenYearsGroup.length<1){
					option.legend.data.pop();
					option.series.pop();
					option.tooltip.formatter += '<br>{a0}:{c0}港元';
				}else{
					for(var p=0;p<tenYearsGroup.length;p++){
						// 费用计算
						option.series[1].data.push(tenYearsGroup[p]);
					}
					// option.tooltip.formatter += '<br>{a0}:{c0}元<br>{a1}:{c1}元';
				}
				for(var q=0;q<tenYears.length;q++){
					// 费用计算
					option.series[0].data.push(tenYears[q]);
				}
				this.drawChat('.costs-chart', option);
			},// 医疗费用预测  参数一：十年费用预测  参数二：同族费用预测(可能为空)
			
			
			drawChat(el, option){
				window[el] = echarts.init(document.querySelector(el));
				// option.animationDuration = 1000;
				window[el].setOption(option,true);
				window.onresize = window[el].resize;
			},// 绘制图表通用
		}
	}
</script>

<style scoped>
	.branch_wrap >>> .van-grid-item__content{
		height: 0.56rem;
		text-align: left;
		padding: 0;
		background-color: #F5F6FA;
		border-radius: 0.28rem;
	}
	.branch_wrap >>> .van-grid-item {
		padding: 0.08rem 0.08rem;
		overflow: auto;
	}
	.branch_wrap >>> .van-grid {
		margin: 0.4rem 0 0.2rem 0;
	}
	.person >>> .van-grid-item__content{
		padding: 0;
	}
	.exper >>> .van-overlay{
		z-index: 99999 !important;
	}
	.illness .sort >>> img{
		width: 0.58rem;
		margin-left: 0.12rem;
	}
	.illness >>> .van-button__text {
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
<style lang="scss" scoped>
	@import "../../assets/iconfont/iconfont.css";
	
	.exper{
		padding: 0 0 0.63rem;
		
		.van-nav-bar{
			z-index: 10000 !important;
		}
		
		// 多次使用样式
		.fonts-40{
			font-size: 0.4rem;
		}
		.c-red{
			color: #FA7893;
		}
		
		// 悬浮按钮
		// .suspend{
		// 	position: fixed;
		// 	right: 0.24rem;
		// 	bottom: 0.48rem;
		// 	width: 1.32rem;
		// 	height: 1.28rem;
		// 	background: url(../../assets/images/experience_recom.png) no-repeat;
		// 	background-size: 100% 100%;
		// 	z-index: 99999;
			
		// }
		
		// banner广告位
		.banner{
			height: 0.88rem;
			background-color: transparent;
		}
		
		// 疑问帮助
		.group_icon{
			color: #B8C0CC;
			font-size: 1em;
			z-index: 1000;
			
			div{
				top: -100%;
				left: -0.32rem;
				width: 5.28rem;
				text-align: left;
				border-radius: 0.08rem;
				padding: 0.18rem 0.24rem;
				line-height: 0.36rem;
				background-color: rgba(38,40,52,.6);
				transform: translateY(-100%);
				box-shadow:0 0.06rem 0.12rem 0 rgba(218,220,227,1);
				opacity: 0;
				transition: all 1s;
				z-index: 100;
				
				//显示描述  默认隐藏
				&.show{
					opacity: 1;
				}
				
				&::after{
					position: absolute;
					bottom: 0;
					left: 0.4rem;
					content: '';
					display: block;
					width: 0.3rem;
					height: 0.3rem;
					background-color: #7D7E85;
					transform: rotateZ(45deg)translateY(50%);
					z-index: 30;
				}
				
				// 弹出位置 默认靠右
				&.center{
					left: -3rem;
					
					&::after{
						left: 3.07rem;
					}
				}
				&.left{
					left: -4.7rem;
					
					&::after{
						left: 4.77rem;
					}
				}
			}
		}
		
		.van-nav-bar{
			background-color: #F5F7FA;
			
			.van-nav-bar__right{
				display: flex;
				align-items: center;
				height: 100%;
				
				.try-again{
					color: #777F8F;
					font-size: 0.2rem;
					line-height: 0.24rem;
				}
			}
		}
		.result{
			color: #777F8F;
			
			&>div{
				padding: 0.24rem 0.24rem 0;
			}
			
			// 个人信息
			.infomation{
				padding: 1rem 0 0.8rem;
				background: url(../../assets/images/di.png) no-repeat;
				background-size: 100% 100%;
				
				.danger{
					width: 2.51rem;
					height: 2.24rem;
					margin: 0 auto;
					padding-top: 0.8rem;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					
					&.jiankang{
						background-image: url(../../assets/images/jiankang.png);
					}
					&.yajiankang{
						background-image: url(../../assets/images/yajiankang.png);
					}
					&.yiliaoxuqiu{
						background-image: url(../../assets/images/yiliaoxuqiu.png);
					}
					
					.danger-val{
						font-size: 0.48rem;
					}
					dt{
						font-size: 0.2rem;
						margin-top: 0.3rem;
					}
				}
				.van-grid{
					margin-top: 0.56rem;
					padding: 0 0.3rem;
					
					.van-grid-item{
						padding: 0.08rem 0.08rem;
						overflow: auto;
					}
				}
				.hint{
					font-size: 0.2rem;
					color: #777F8F;
					margin-top: 0.06rem;
				}
			}
			.person{
				margin-top: 0.4rem;
				padding: 0 0.1rem;
				
				dl{
					margin: 1rem auto;
					
					dt{
						color: #999999;
					}
					b{
						font-size: 0.42rem;
					}
				}
			}
			
			// 通用标题样式
			.module-title{
				position: relative;
				font-size: 0.36rem;
				font-weight: 600;
				color: #40444D;
				text-align: left;
				margin-bottom: 0.28rem;
				
				&::after{
					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					width: 1.94rem;
					height: 0.16rem;
					background:linear-gradient(to right, rgba(102,129,250,1), rgba(102,129,250,0));
					border-radius: 0.08rem 0.08rem 0 0;
					z-index: -1;
				}
				& + p{
					font-weight: 400;
					line-height: 0.44rem;
				}
			}
			
			// 体型评估
			.bodily{
				margin: 0.68rem 0 1.12rem 0;
				
				div.qjc-clearfix{
					&>div{
						padding: 0.55rem 1.08rem;
						
						p{
							line-height: 0.44rem;
						}
					}
				}
				.group{
					line-height: 0.44rem;
					color: #6A7180;
					background-color: #F5F8FC;
					padding: 0.24rem 0;
					border-radius: 0.08rem;
				}
			}
			
			// 重症预警(医疗需求者) 慢病风险(亚健康) 系统风险(健康)
			// 四高风险(亚健康)样式相同
			.illness,
			.three-hights{
				margin-bottom: 1.17rem;
				
				&>div{
					margin-bottom: 0.69rem;
					
					&>dl{
						// background-color: #F5F8FC;
						// padding: 0.31rem 0;
						// border-radius: 0.08rem;
						// margin: 0.42rem 0 0.4rem;
						margin: 0.2rem 0 0.1rem;
						
						&>dt{
							padding-bottom: 0.28rem;
						}
						&>dd{
							padding: 0 0.44rem;
							border-top: 0.02rem solid #fff;
							
							dl{
								margin-top: 0.38rem;
								
								dt{
									line-height: 0.36rem;
								}
								dd{
									b{
										font-size: 0.4rem;
									}
								}
							}
						}
					}
					.symptom{
						line-height: 0.44rem;
						
						span{
							color: #A1A4B3;
						}
					}
				}
			}
			
			.illness{
				margin-top: 1rem;
				
				.sort{
					top: 0.05rem;
					// left: 0.31rem;
					line-height: 0.32rem;
					padding: 0 0.06rem;
					font-weight: 500;
					color: #777F8F;
					border: 0.01rem solid #D3DAE3;
					border-radius: 0.02rem;
					
				}
				.van-image{
					position: relative;
					z-index: 1000;
					width: 0.72rem;
				}
				dt.devg{
					span.text-c{
						display: block;
						text-align: left;
						// padding-left: 2.4rem;
						padding-left: 2.0rem;
					}
				}
				dd{
					.van-image{
						margin-top: 0.12rem;
					}
				}
			}
			
			// 体型改善
			.bodily-improve{
				&>div{
					margin-bottom: 0.64rem;
					
					&>.van-image{
						margin: 0.4rem 0 0.48rem;
					}
					&>div.qjc-clearfix{
						background-color: #F5F8FC;
						border-radius: 0.08rem;
						padding: 0.24rem 0;
						
						dl{
							width: 50%;
							
							&.qjc-fl{
								position: relative;
								
								&::after{
									content: '';
									position: absolute;
									top: 0.12rem;
									right: 0;
									bottom: 0.12rem;
									width: 0.02rem;
									background-color: #9EA5C9;
								}
							}
							dd{
								.van-image{
									position: relative;
									margin-top: 0.1rem;
									z-index: 10000;
								}
							}
						}
					}
				}
			}
		
			// 饮食建议
			.diet-advice{
				margin-top: 1.12rem;
				
				.module-title{
					margin-bottom: 0.5rem;
				}
				&>div{
					height: 1.12rem;
					line-height: 0.3rem;
					background-color: #F5F8FC;
					border-radius: 0.08rem;
					padding: 0.2rem 0;
					margin-bottom: 0.16rem;
					
					&.diet-advice-title{
						height: 1.28rem;
						padding: 0.28rem;
						
						dt{
							margin-bottom: 0.12rem;
						}
					}
					.qjc-fl{
						position: relative;
						
						&::after{
							content: '';
							position: absolute;
							top: 0.08rem;
							right: 0;
							bottom: 0.08rem;
							width: 0.02rem;
							background-color: #9EA5C9;
						}
					}
					dt{
						margin-bottom: 0.08rem;
					}
					dd{
						.van-image{
							position: relative;
							margin-left: 0.1rem;
							z-index: 10000;
						}
					}
					dd.maintain{
						b{
							color: #6A7180;
							font-size: 0.3rem;
						}
					}
					&>div{
						display: flex;
						align-items: center;
						justify-content: center;
						
						.van-image{
							margin-right: 0.15rem;
						}
						dl{
							min-width: 1.2rem;
						}
					}
				}
				.explain{
					line-height: 0.36rem;
				}
			}
		
			// 医疗费用预测
			.cost{
				margin-top: 0.64rem;
				
				.module-title::after{
					width: 3.42rem;
				}
				.van-image{
					margin: 0.48rem auto;
				}
				dl{
					background-color: #F5F8FC;
					line-height: 0.44rem;
					padding: 0.32rem 0;
					
					dt{
						color: #6A7180;
						margin-bottom: 0.12rem;
					}
				}
			}
					
			
		}
	
		//过渡扫描
		.load{
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #5c77ef url(../../assets/images/experience_load_bg.png) no-repeat center center;
			background-size: 12.42rem 26.88rem;
			z-index: 99999;
			
			&>div{
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				height: 100%;
				transform: translate(-50%,-50%);
				
				&>.van-image{
					position: absolute;
				}
				.img1{
					top: 0.6rem;
					right: 0.32rem;
				}
				.img2{
					top: 8.95rem;
					right: 0.26rem;
				}
				.img3{
					top: 4.07rem;
					right: 0.32rem;
				}
				.img4{
					top: 0.59rem;
					left: 0.32rem;
				}
				.img5{
					top: 8.43rem;
					left: 0.34rem;
				}
				.person{
					position: absolute;
					top: 1.07rem;
					left: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 3.31rem;
					height: 8.7rem;
					background: url(../../assets/images/experience_load_person_border.png) no-repeat;
					background-size: 100% 100%;
					transform: translateX(-50%);
					
					.border{
						position: relative;
						width: 2.73rem;
						height: 7.96rem;
						z-index: 10;
						overflow: hidden;
					}
					.person-bg{
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
					}
					.line{
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						animation: scan 1.5s linear infinite alternate;
						z-index: 2;
						
						@keyframes scan{
							from{
								top: 0;
							}
							to{
								top: 100%;
							}
						}
					}
				}
				.progress{
					position: absolute;
					top: 10.25rem;
					left: 50%;
					transform: translateX(-50%);
					display: flex;
					align-items: center;
					width: 6.78rem;
					height: 1.38rem;
					background: url(../../assets/images/experience_load_progress.png) no-repeat;
					background-size: 100% 100%;
					overflow: hidden;
					
					dl{
						position: relative;
						width: 100%;
						color: #F5F5FA;
						z-index: 10;
						
						dt{
							font-size: 0.56rem;
							font-weight: 500;
							line-height: 0.56rem;
						}
						dd{
							line-height: 0.24rem;
							margin-top: 0.08rem;
						}
					}
					.van-image{
						position: absolute;
						top: 0;
						left: 0;
						margin-left: -100%;
						z-index: 9;
					}
				}
				
			}
		}
	
		//长按存图
		.saveLong{
			top: 0;
			left: 0;
			opacity: 0;
			z-index: 9999;
		}
	
		// 了解更多
		.know-more{
			margin-top: 1.12rem;
			
			.price{
				top: 2.37rem;
				left: 1.1rem;
				color: #F66A6A;
				font-weight: 500;
				line-height: 0.28rem;
				z-index: 10001;
				
				span{
					margin-left: -0.04rem;
				}
			}
			.van-image{
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 10000;
			}
		}
		// 弹窗
		.more{
			background-color: transparent;
			z-index: 999999 !important;
			
			.price{
				top: 2.4rem;
				left: 1rem;
				color: #F66A6A;
				line-height: 1;
				
				span{
					margin-left: -0.03rem;
				}
			}
			&>.van-image{
				margin-top: 0.64rem;
			}
		}
		
		.footer{
			font-size: 0.2rem;
			line-height: 0.2rem;
			color: #6A7180;
			text-align: center;
			margin-top: 0.64rem;
		}
		
	}
	
	.result_mlev {
		width: 100%;
		padding: 0.24rem 0.24rem 0 !important;
		background-color: #f6f7fa;
		
		.result_header {
			width: 100%;
			padding: 0.24rem 0;
			border-radius: 0.16rem;
			// margin-top: 0.24rem;
			background: rgba(255, 255, 255, 1);
		}
		.result_header span {
			font-size: 0.6rem;
		}
		.result_header_desc {
			text-align: center;
			color: rgba(140, 145, 154, 1);
			font-family: PingFang SC;
			font-weight: 400;
			font-size: 0.24rem;
			line-height: 0.34rem;
		}
		.result_header_title {
			text-align: center;
			// color: rgba(250, 81, 81, 1);
			font-family: PingFang SC;
			font-weight: 600;
			font-size: 0.36rem;
			line-height: 0.5rem;
			margin-top: 0.04rem;
		}
		.title_color1 {
			color: #23cb29;
		}
		.title_color2 {
			color: #ef8f00;
		}
		.title_color3 {
			color: rgba(250, 81, 81, 1);
		}
	}
	.chart{
		height: 5.35rem;
	}
	.costs-chart{
		height: 5.4rem;
	}
	.costs-chart_title {
		position: relative;
		top: 0.40rem;
		z-index: 99;
		color: rgba(41, 48, 51, 1);
		font-family: PingFang SC;
		font-weight: 600;
		font-size: 0.30rem;
		line-height: 0.40rem;
		text-align: left;
	}
	
	.bmi_tips {
		font-weight: 400;
		line-height: 0.44rem;
		// color: #40444D;
		font-size: 0.28rem;
		margin-top: 0.3rem;
		text-align: left;
	}
	
	
	
	.branch {
		width: 100%;
		padding: 0.24rem;
		background-color: #fff;
	}
	.branch_score {
		position: relative;
		margin-bottom: 0.16rem;
	}
	.branch_score .branch_content {
		padding-top: 0.88rem;
		border-radius: 0.12rem;
		
		.arrow{
			position: absolute;
			top: -0.02rem !important;
			left: 0.86rem !important;
			z-index: 4 !important;
			width: 0.4rem !important;
			height: 0.4rem !important;
			background-color: #fff !important;
			transform: rotateZ(45deg)translateX(-50%);
		}
	}
	.branch_item_title {
		position: absolute;
		top: 0;
		left: -0.02rem;
		display: flex;
		align-items: center;
		width: 2.2rem;
		height: 0.66rem;
		padding-bottom: 0.08rem;
		font-family: PingFang SC;
		font-weight: 500;
		font-size: 0.32rem;
		padding: 0 0.24rem 0.08rem;
		color: #fff;
		background: url(../../assets/images/result_score_title.png);
		background-size: 100% 100%;
		// box-shadow: 0px -2px 6px rgba(56, 81, 130, 0.25) inset;
	}
	.branch_sangao_title {
		position: absolute;
		top: 0;
		left: -0.02rem;
		display: flex;
		align-items: center;
		width: 4.9rem;
		height: 0.66rem;
		padding-bottom: 0.08rem;
		font-family: PingFang SC;
		font-weight: 500;
		font-size: 0.32rem;
		padding: 0 0.24rem 0.08rem;
		color: #fff;
		background: url(../../assets/images/score_title_bg2.png);
		background-size: 100% 100%;
	}
	.branch_dl_title {
		position: absolute;
		top: 0;
		left: -0.02rem;
		display: flex;
		align-items: center;
		width: 5.8rem;
		height: 0.66rem;
		padding-bottom: 0.08rem;
		font-family: PingFang SC;
		font-weight: 500;
		font-size: 0.32rem;
		padding: 0 0.24rem 0.08rem;
		color: #fff;
		background: url(../../assets/images/score_title_bg3.png);
		background-size: 100% 100%;
	}
	.score_info {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 100%;
		height: 1.30rem;
		color: rgba(59, 69, 73, 1);
		font-family: PingFang SC;
		font-weight: 500;
		font-size: 0.28rem;
		background: rgba(242, 247, 255, 1);
		border-radius: 0.12rem;
	}
	.score_wrap {
		display: flex;
		align-items: center;
	}
	.score_info_top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		width: 100%;
		height: 1.30rem;
		color: rgba(59, 69, 73, 1);
		font-family: PingFang SC;
		font-weight: 500;
		font-size: 0.28rem;
		background: rgba(242, 247, 255, 1);
		border-radius: 0.12rem;
		text-align: left;
		padding: 0.24rem 0.32rem;
	}
	.score_info_top::before {
		content: "";  
		width: 0;  
		height: 0;  
		border: 0.2rem solid rgba(242, 247, 255, 1);
		border-color: rgba(242, 247, 255, 1) transparent transparent transparent;
		position:absolute;  
		bottom: -0.36rem;  
		left: var(--infoLeft);
	}
	.score_info_top_info {
		display: flex;
		align-items: center;
		margin-left: 0.08rem;
	}
	.score_info_top_btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.36rem;
		height: 0.56rem;
		border-radius: 0.40rem;
		color: rgba(255, 255, 255, 1);
		font-family: PingFang SC;
		font-weight: 500;
		font-size: 0.22rem;
		background: linear-gradient(5.18deg, rgba(102, 129, 250, 1) 3.09%, rgba(148, 168, 255, 1) 95.17%);
	}
	.score_info::before {
		content: "";  
		width: 0;  
		height: 0;  
		border: 0.2rem solid rgba(242, 247, 255, 1);
		border-color: rgba(242, 247, 255, 1) transparent transparent transparent;
		position:absolute;  
		bottom: -0.36rem;  
		left: var(--infoLeft);
	}
	.score_line {
		position: relative;
		width: 100%;
		height: 0.12rem;
		border-radius: 0.5rem;
		margin-top: 0.3rem;
		background-color: rgb(219, 228, 242);
		box-shadow: 0 0.2rem 0.2rem rgba(81, 159, 232, 0.15) inset;
	}
	.score_line::before {
		content: '0';
		position: absolute;
		top: 0.2rem;
		left: 0;
		color: rgba(108, 130, 168, 1);
		font-family: PingFang SC;
		font-size: 0.24rem;
		line-height: 0.22rem;
	}
	.score_one {
		position: absolute;
		width: 59%;
		height: 0.12rem;
		border-radius: 0.5rem 0 0 0.5rem;
		background-color: rgba(254, 90, 110, 1);
		box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
	}
	.score_one_bg {
		position: absolute;
		width: 59%;
		height: 0.12rem;
		border-radius: 0.5rem 0 0 0.5rem;
		// background-color: rgba(254, 90, 110, 1);
		// box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
		// border-right: 0.08rem solid #fff;
	}
	.score_one_bg::after {
		content: '59';
		position: absolute;
		top: 0.2rem;
		right: -0.12rem;
		color: rgba(108, 130, 168, 1);
		font-family: PingFang SC;
		font-size: 0.24rem;
		line-height: 0.22rem;
	}
	.score_line_top {
		width: 100%;
		height: 0.12rem;
		border-radius: 0.5rem;
		background-color: rgb(219, 228, 242);
		box-shadow: 0 0.2rem 0.2rem rgba(81, 159, 232, 0.15) inset;
	}
	.score_d1 {
		position: absolute;
		left: 58%;
		width: 0.08rem;
		height: 0.12rem;
		background-color: #fff;
		z-index: 99;
	}
	.score_d2 {
		position: absolute;
		left: 88%;
		width: 0.08rem;
		height: 0.12rem;
		background-color: #fff;
		z-index: 99;
	}
	.score_circle1 {
		position: absolute;
		top: -0.06rem;
		left: 0;
		width: 0.22rem;
		height: 0.22rem;
		border-radius: 50%;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0 0.02rem 0.04rem rgba(165, 209, 149, 1);
		z-index: 100;
	}
	.score_circle2 {
		position: absolute;
		top: -0.06rem;
		left: 0;
		width: 0.22rem;
		height: 0.22rem;
		border-radius: 50%;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0 0.02rem 0.04rem rgba(223, 202, 149, 1);
		z-index: 100;
	}
	.score_circle3 {
		position: absolute;
		top: -0.06rem;
		left: 0;
		width: 0.22rem;
		height: 0.22rem;
		border-radius: 50%;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0 0.02rem 0.04rem rgba(223, 171, 154, 1);
		z-index: 100;
	}
	.score_two {
		position: absolute;
		width: 30%;
		left: 59%;
		height: 0.12rem;
		// border-radius: 0.5rem 0 0 0.5rem;
		background-color: rgba(255, 204, 26, 1);
		box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
		// border-right: 0.08rem solid #fff;
	}
	.score_two_bg {
		position: absolute;
		width: 30%;
		left: 59%;
		height: 0.12rem;
	}
	.score_two_bg::after {
		content: '89';
		position: absolute;
		top: 0.2rem;
		right: -0.12rem;
		color: rgba(108, 130, 168, 1);
		font-family: PingFang SC;
		font-size: 0.24rem;
		line-height: 0.22rem;
	}
	.score_three_bg {
		position: absolute;
		width: 11%;
		left: 89%;
		height: 0.12rem;
		border-radius: 0 0.5rem 0.5rem 0;
		// background-color: rgb(31, 211, 169);
		// box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
	}
	.score_three {
		position: absolute;
		width: 11%;
		left: 89%;
		height: 0.12rem;
		border-radius: 0 0.5rem 0.5rem 0;
		background-color: rgb(31, 211, 169);
		box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
	}
	.score_three_bg::after {
		content: '100';
		position: absolute;
		top: 0.2rem;
		right: 0rem;
		color: rgba(108, 130, 168, 1);
		font-family: PingFang SC;
		font-size: 0.24rem;
		line-height: 0.22rem;
	}
	.score_text {
		color: rgba(106, 113, 128, 1);
		font-family: PingFang SC;
		font-size: 0.24rem;
		line-height: 0.36rem;
		text-align: left;
		margin-top: 0.5rem;
	}
	.branch_course {
		margin-bottom: 0.16rem;
	}
	.branch_header {
		width: 100%;
		height: 0.92rem;
		color: rgba(87, 93, 105, 1);
		font-family: PingFang SC;
		font-weight: 500;
		font-size: 0.3rem;
		display: flex;
		align-items: center;
		padding: 0 0.24rem;
		border-radius: 0.12rem 0.12rem 0 0;
		background: linear-gradient(90.04deg, rgba(238, 245, 255, 1) 0.05%, rgba(248, 251, 255, 0) 100.07%);
	}
	.branch_content {
		position: relative;
		width: 100%;
		padding: 0.24rem;
		border-radius: 0 0 0.12rem 0.12rem;
		border: 1px solid rgba(211, 213, 222, 1);
		box-sizing: border-box;
		background: rgba(255, 255, 255, 1);
	}
	.branch_type_list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.12rem;
	}
	.branch_type_item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.75rem;
		height: 0.76rem;
		color: rgba(102, 129, 250, 1);
		font-family: PingFang SC;
		font-weight: 500;
		font-size: 0.28rem;
		border: 1px solid rgba(167, 183, 255, 1);
		border-radius: 0.60rem;
	}
	.branch_type_jt {
		width: 0.43rem;
		height: 0.18rem;
		background: url(../../assets/images/branch_jt.png) no-repeat;
		background-size: 100% 100%;
	}
	.branch_100 {
		width: 100%;
	}
	.branch_type_text {
		color: rgba(59, 69, 73, 1);
		font-family: PingFang SC;
		font-size: 0.28rem;
		line-height: 0.44rem;
		text-align: left;
		margin-top: 0.16rem;
	}
	.branch_type_c {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.2rem;
		height: 1.2rem;
		padding: 0 0.2rem;
		color: rgba(102, 129, 250, 1);
		font-family: PingFang SC;
		font-weight: 500;
		font-size: 0.28rem;
		line-height: 0.32rem;
		border: 1px solid rgba(167, 183, 255, 1);
		border-radius: 50%;
	}
	.branch_yl {
		color: rgba(59, 69, 73, 1);
		font-family: PingFang SC;
		font-size: 0.28rem;
		line-height: 0.44rem;
		margin-bottom: 0.2rem;
		text-align: left;
	}
	.branch_yl span, .branch_yjk span{
		color: rgba(102, 129, 250, 1);
		font-family: YouSheBiaoTiHei;
		font-size: 0.32rem;
		line-height: 0.44rem;
		font-weight: bold;
	}
	.branch_yjk {
		color: rgba(59, 69, 73, 1);
		font-family: PingFang SC;
		font-size: 0.28rem;
		line-height: 0.44rem;
		margin-bottom: 0.2rem;
		text-align: left;
	}
	.z_index9 {
		z-index: 101;
		margin-left: 0.1rem;
	}
	.score_tip_box {
		width: 6.1rem;
		left: -0.78rem;
		top: 0.6rem;;
		padding: 0.24rem;
		color: rgba(59, 69, 73, 1);
		font-weight: 400;
		font-family: PingFang SC;
		font-size: 0.28rem;
		line-height: 0.44rem;
		border-radius: 0.12rem;
		box-shadow: 0.06rem 0.06rem 0.16rem rgba(47, 68, 87, 0.15);
		text-align: left;
	}
	.score_tip_span{
		display: block;
		font-weight: 500;
		color: rgba(59, 69, 73, 1);
		margin-top: 0.2rem;
	}
	.branch_wrap {
		padding: 0 0.24rem;
	}
	.branch_health_list {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.branch_health_item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2.1rem;
		height: 1.18rem;
		color: rgba(119, 127, 143, 1);
		font-family: PingFang SC;
		font-size: 0.3rem;
		border-radius: 0.08rem;
		// border: 1px solid rgba(211, 213, 222, 1);
		// background: url(../../assets/images/branch_health_3.png) no-repeat;
		background: url(../../assets/images/branch_health_bg.png) no-repeat;
		background-size: 99.9% 99.9%;
	}
	.branch_health_item_ca {
		font-size: 0.24rem;
	}
	.branch_health_1 {
		width: 2.1rem;
		height: 1.18rem;
		color: rgb(20, 203, 160);
		background: url(../../assets/images/branch_health_1.png) no-repeat;
		background-size: 100% 100%;
	}
	.branch_health_2 {
		width: 2.1rem;
		height: 1.18rem;
		color: rgb(245, 162, 0);
		background: url(../../assets/images/branch_health_2.png) no-repeat;
		background-size: 100% 100%;
	}
	.branch_health_3 {
		width: 2.1rem;
		height: 1.18rem;
		color: rgba(250, 102, 102, 1);
		background: url(../../assets/images/branch_health_3.png) no-repeat;
		background-size: 100% 100%;
	}
	.branch_health_item_text {
		flex: 1;
		color: rgba(59, 69, 73, 1);
		font-family: PingFang SC;
		font-size: 0.28rem;
		line-height: 0.28rem;
		margin-top: 0.2rem;
	}
	.branch_health_scale {
		transform: scale(1.12);
		font-weight: 500;
	}
	
	.score_val {
		font-family: Helvetica;
		font-weight: bold;
		font-size: 0.48rem;
	}
	.xiao_icon {
		width: 0.56rem;
		height: 0.48rem;
	}
	.score_color1 {
		color: rgb(20, 203, 160);
	}
	.score_color2 {
		color: rgb(254, 152, 0);
	}
	.score_color3 {
		color: rgb(250, 102, 102);
	}
	.ai_jy {
		width: 100%;
		margin-top: 0.32rem;
	}
	.ai_jy_close {
		width: 100%;
		display: flex;
		align-items: flex-start;
		
	}
	.ai_jy_query {
		flex: 1;
		padding: 0.12rem 0.12rem 0.2rem 0.24rem;
		margin-left: 0.24rem;
		border-radius: 0.2rem;
		border: 0.01rem solid #6882FB;
		text-align: left;
	}
	.ai_jy_query_p {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 0.28rem;
		color: #3A3A3A;
		line-height: 0.48rem;
		text-align: left;
	}
	.ai_jy_query_p span {
		color: #6882FB;
		font-weight: 500;
	}
	.ai_jy_btn_cell {
		width: 100%;
		margin-top: 0.16rem;
	}
	.ai_jy_btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2rem;
		height: 0.56rem;
		padding: 0;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 0.28rem;
		color: #FFFFFF;
		background: linear-gradient( 315deg, #336FE7 0%, #B49CFD 100%);
		border-radius: 0.28rem;
		margin: 0 auto;
	}
	.ai_jy_btn_icon {
		width: 0.46rem !important;
		height: 0.46rem;
	}
	.close_avatar {
		width: 0.88rem !important;
		height: 0.88rem;
		border-radius: 50%;
	}
	.ai_jy_open {
		width: 100%;
		margin-top: 0.32rem;
	}
	.ai_jy_q {
		width: 100%;
		display: flex;
		align-items: center;
	}
	.ai_jy_q_cell {
		// flex: 1;
		display: flex;
		align-items: center;
		text-align: left;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 0.28rem;
		color: #3A3A3A;
		padding: 0.1rem 0.2rem;
		margin-left: 0.22rem;
		background: #F2F6FF;
		border-radius: 0.12rem;
	}
	.ai_jy_content {
		width: 100%;
		display: flex;
		align-items: flex-start;
		margin-top: 0.36rem;
	}
	.ai_jy_html {
		flex: 1;
		margin-left: 0.24rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 0.28rem;
		color: #3A3A3A;
		line-height: 0.48rem;
		padding: 0.12rem 0.2rem 0.26rem;
		border-radius: 0.2rem;
		border: 0.01rem solid #6882FB;
		text-align: left;
	}
	.ai_jy_load {
		width: 100%;
		min-height: 2rem;
		display: flex;
		align-items: flex-start;
	}
	.ai_jy_load_cell {
		margin-left: 0.24rem;
		background-color: #F5F8FC;
		border-radius: 0.12rem;
	}
	.chat_text {
		padding: 0.24rem;
		border-radius: 0.12rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 0.28rem;
		color: #3A3A3A;
		line-height: 0.48rem;
	}
	
	.tips1 {
		position: relative;
		display: inline-block;
		width: 0.16rem;
		height: 0.16rem;
		background-image: linear-gradient(90deg, #90EE90,#00FF00);
		border-radius: 0.06rem;
		animation: fly 1.5s linear infinite;
	}
	@keyframes fly {
	  0% {
		top: 0
	  }
	  33% {
	    top: 0.05rem
	  }
	  66% {
	    top: -0.05rem
	  }
	  100% {
		top: 0
	  }
	}
	.tips2 {
		position: relative;
		display: inline-block;
		width: 0.16rem;
		height: 0.16rem;
		background-image: linear-gradient(90deg, #FFD700,#FFA500);
		border-radius: 0.06rem;
		margin-left: 0.10rem;
		animation: fly2 1.5s linear infinite;
	}
	@keyframes fly2 {
	  0% {
		top: 0
	  }
	  34% {
	    top: 0.05rem
	  }
	  66% {
	    top: -0.05rem
	  }
	  100% {
		top: 0
	  }
	}
	.tips3 {
		position: relative;
		display: inline-block;
		width: 0.16rem;
		height: 0.16rem;
		background-image: linear-gradient(90deg, #FF6347,#FF0000);
		border-radius: 0.06rem;
		margin-left: 0.10rem;
		animation: fly3 1.5s linear infinite;
	}
	@keyframes fly3 {
	  0% {
		top: 0
	  }
	  34% {
	    top: 0.05rem
	  }
	  66% {
	    top: -0.05rem
	  }
	  100% {
		top: 0
	  }
	}
</style>
